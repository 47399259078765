<template>
  <div class="flex flex-col gap-4 w-full">
    <c-card class="w-full flex flex-col p-5 pt-11 gap-0">
      <div class="flex justify-between items-center">
        <div class="flex justify-start items-center gap-4">
          <div class="flex justify-start items-center gap-1">
            <c-icon icon-name="icon-dot" size="xxs" class="text-mediumSeaGreen" />
            <p class="text-sm font-normal text-jet capitalize">Available</p>
          </div>
          <div class="flex justify-start items-center gap-1">
            <c-icon icon-name="icon-dot" size="xxs" class="text-flame" />
            <p class="text-sm font-normal text-jet capitalize">Busy</p>
          </div>
          <div class="flex justify-start items-center gap-1">
            <c-icon icon-name="icon-dot" size="xxs" class="text-blueCrayola" />
            <p class="text-sm font-normal text-jet capitalize">Tentative</p>
          </div>
        </div>
        <template v-if="activeView === 'register'">
          <div @click="onSwitchActiveView('calendar')" class="flex justify-start items-center gap-2">
            <c-icon icon-name="calendar-broken" size="s" style="fill:transparent;stroke:#F15A29" />
            <p class="text-base font-semibold text-flame hover:underline cursor-pointer">Calendar View</p>
          </div>
        </template>
        <template v-else-if="activeView === 'calendar'">
          <div @click="onSwitchActiveView('register')" class="flex justify-start items-center gap-2">
            <c-icon icon-name="calendar-broken" size="s" style="fill:transparent;stroke:#F15A29" />
            <p class="text-base font-semibold text-flame hover:underline cursor-pointer">Register View</p>
          </div>
        </template>
      </div>
      <CardFooter
        @actionModal="isOpenFilter = true"
        @sortType="$queryBuilder({ sort: $event }, getSchedules)"
        @searchResult="$queryBuilder({ search: $event }, getSchedules)"
      >
        <template v-slot:rightItems>
            <div class="flex justify-start items-center gap-4 h-1/2">
              <div class="flex justify-between items-center p-4 gap-4">
                <p class="font-bold text-darkPurple text-lg">{{ $DATEFORMAT(new Date(selectedDate), 'MMMM yyyy') }}</p>
                <div class="flex justify-start items-center gap-6">
                  <span class="cursor-pointer hover:bg-cultured p-2 rounded" @click="$refs.$c.previous()">
                    <c-icon icon-name="icon-arrow-left" size="xs" />
                  </span>
                  <span class="cursor-pointer hover:bg-cultured p-2 rounded" @click="$refs.$c.next()">
                    <c-icon icon-name="icon-arrow-right" size="xs" />
                  </span>
                </div>
              </div>
              <div class="ml-3 -mt-2 flex justify-start items-center gap-0">
                <c-icon icon-name="sort_size" class="cursor-pointer mt-2" size="xs" />
                <c-select
                  :options="filterByWeekOptions"
                  placeholder="Filter By"
                  class="select-class"
                  @input="$queryBuilder({ period: $event }, getSchedules)"
                  />
              </div>
              <div class="border-l border-romanSilver pl-3" @click="isOpenSchedule = true">
                <c-button label="New Schedule" class="c-button button_class" />
              </div>
            </div>
        </template>
      </CardFooter>
    </c-card>

    <template v-if="activeView === 'register'">
      <c-table
        :headers="headers"
        :items="scheduleOptions"
        aria-label="ClockInOut Table"
        :loading="isFetching"
        :has-number="true"
        :page-sync="true"
        :pagination-list="scheduleMeta"
        @page="$queryBuilder({ page: $event }, getSchedules)"
        @itemsPerPage="$queryBuilder({ perPage: $event }, getSchedules)"
        v-if="scheduleOptions.length || isFetching"
      >
        <template v-slot:item="{ item }">
          <span v-if="item.tag" class="text-darkPurple text-sm">
            {{ item.data.tag }}
          </span>
          <span v-if="item.name" class="text-darkPurple text-sm">
            {{ item.data.name }}
          </span>
          <span v-if="item.startDate" class="text-darkPurple text-sm">
            {{ $DATEFORMAT(new Date(item.data.startDate), 'MMM dd, yyyy') }}
          </span>
          <span v-if="item.endDate" class="text-darkPurple text-sm">
            {{ $DATEFORMAT(new Date(item.data.endDate), 'MMM dd, yyyy') }}
          </span>
          <span v-if="item.workDays" class="text-darkPurple text-sm">
            {{ item.data.workDays.join(', ') }}
          </span>
          <span v-if="item.employees" class="text-darkPurple text-sm">
            {{ item.data.employees.length }}
          </span>
          <div v-if="item.status" class="rounded-md p-2 text-sm w-28 text-center capitalize"
            :class="{
              'bg-green-100 text-mediumSeaGreen': item.data.status === 'active',
              'bg-red-100 text-flame': item.data.status === 'inactive',
              'bg-red-100 text-flame': !item.data.status
            }">
            {{ item.data.status ? item.data.status : 'Inactive' }}
          </div>
          <c-menu v-if="item.id" left @click.stop="">
            <template v-slot:title>
              <c-icon icon-name="more_icon" size="xs" />
            </template>
            <div class="flex flex-col w-56 h-18 p-2 justify-start items-start">
              <div class="w-full" @click="() => {}">
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                    <c-icon icon-name="icon-eye" class-name="text-darkPurple" size="xs" />
                    <span class="text-darkPurple text-sm leading-5 font-normal">View Employees</span>
                  </div>
                </div>
              </div>
              <div class="w-full" @click="() => {}">
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                    <c-icon icon-name="icon-edit" class-name="text-blueCrayola" size="xs" />
                    <span class="text-darkPurple text-sm leading-5 font-normal">Manage</span>
                  </div>
                </div>
              </div>
              <div class="w-full" @click="() => {}">
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                    <c-icon icon-name="icon-add" class-name="text-mediumSeaGreen" size="xs" />
                    <span class="text-darkPurple text-sm leading-5 font-normal">Add Employees</span>
                  </div>
                </div>
              </div>
              <div class="w-full" @click="() => {}">
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                    <c-icon icon-name="arrow_exchange" class-name="text-darkPurple" size="xs" />
                    <span class="text-darkPurple text-sm leading-5 font-normal">Share</span>
                  </div>
                </div>
              </div>
              <div class="w-full" @click="() => {}">
                <div class="hover:bg-ghostWhite w-full rounded-md">
                  <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                    <c-icon icon-name="icon-terminate" class-name="text-desire" size="xs" />
                    <span class="text-darkPurple text-sm leading-5 font-normal">Deactivate</span>
                  </div>
                </div>
              </div>
            </div>
          </c-menu>
        </template>
      </c-table>

      <div v-else class="flex flex-col justify-center items-center gap-0 px-10">
        <c-icon icon-name="paygrade_Illustration" size="" class-name="w-56 h-56" />
        <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
          Time tracking locations can be found here. This is empty now,
          but you can start adding time tracking locations to populate this area.
        </p>
      </div>
    </template>

    <calendar
      ref="$c"
      :time="false"
      hide-title-bar
      hide-view-selector
      :events="conflictedLeave"
      :selected-date="selectedDate"
      events-on-month-view
      :disable-views="['years', 'year', 'week', 'day']"
      :cell-contextmenu="true"
      @view-change="onChangeView($event)"
      class="calendar-class"
      v-if="activeView === 'calendar'"
    >
      <template #event="{ event }">
        <template v-if="isEqual(new Date(new Date(event.date).getMonth()), new Date(new Date(selectedDate).getMonth()))">
          <div @click="isOpen = true, activeConflict = event" class="flex justify-center items-center gap-2 cursor-pointer">
            <p class="text-desire text-xs bg-red-100 border border-desire border-solid rounded-md p-2 w-full">
              {{ event.conflict.length }} Employee <span class="underline ml-1">View</span>
            </p>
          </div>
        </template>
      </template>
    </calendar>

    <RightSideBar
      v-if="isOpen"
      @close="isOpen = false"
      :close-button="true"
      width="width:520px"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center gap-0">
          <back-button variant="secondary" style="padding:0" @click="isOpen = false" />
          <span class="font-bold text-lg text-darkPurple">View Employees</span>
        </div>
      </template>
      <template v-slot:sub-title>
        <div class="flex flex-col justify-start items-start gap-2">
          <div class="flex justify-start items-center gap-0">
            <back-button variant="secondary" style="padding:0" @click="isOpen = false" />
            <span class="font-bold text-lg text-darkPurple">View Employees</span>
          </div>
          <span class="font-normal text-sm text-romanSilver">
            The system only shows conflicts for the same job role and function.
          </span>
        </div>
      </template>

        <div v-if="true" class="flex flex-col justify-start gap-12">
          <div class="w-full flex flex-col justify-start items-start gap-6">
            <p class="text-darkPurple">Absent employees  for 2nd August, 2023</p>
            <div class="w-full flex justify-between items-center relative">
              <search-input
                class="w-full"
                @input="getOrganisationEmployees($event, 100)"
                placeholder="Search employee(s) or filter by"
              />
              <c-icon icon-name="sort_size" size="xs" class="absolute right-4 cursor-pointer" />
            </div>
          </div>
          <div>
            <div v-for="(employee, i) in absentEmployees" :key="JSON.stringify(item) + i"
              class="w-full flex flex-col justify-start items-start gap- border-b border-romanSilver border-dashed py-4"
            >
              <div class="flex justify-start items-start gap-2" :class="{'justify-end': index % 2}">
                <div>
                  <span v-if="employee.photo" class="flex justify-center items-center w-11 h-11">
                    <img :src="employee.photo" class="w-11 h-11 rounded" alt="profile photo" />
                  </span>
                  <div v-else class="flex justify-center items-center w-11 h-11 rounded border">
                    <span class="text-blueCrayola text-center font-semibold text-lg p-2">
                      {{ $getInitials(`${employee.fname} ${employee.lname}`) }}
                    </span>
                  </div>
                </div>
                <div class="flex flex-col justify-start gap-1">
                  <p class="font-semibold text-darkPurple text-base">
                    {{ employee.fname }} {{ employee.lname }}
                  </p>
                  <div class="flex justify-start items-center gap-2">
                    <p class="font-bold text-xs text-romanSilver uppercase">
                      {{ employee.orgFunction && employee.orgFunction }}
                    </p>
                    <p class="font-semibold text-xs text-romanSilver uppercase">
                      • {{ employee.userDesignation && employee.userDesignation }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex flex-col justify-center items-center gap-0">
          <c-icon icon-name="empty-conflicts" size="" class="w-80" />
          <p class="text-base text-jet -mt-10">There is absent employees for this month.</p>
        </div>
    </RightSideBar>

    <validation-observer ref="observer" rules="required" v-slot="{ handleSubmit }">
      <RightSideBar
        v-if="isOpenSchedule"
        @close="isOpenSchedule = false"
        @submit="handleSubmit(onSubmitSchedule)"
        :loading="isSubmitting"
        width="width:520px"
        submit="Save"
        :button-class="`bg-dynamicBackBtn text-white rounded`"
      >
        <template v-slot:title>
          <span class="font-bold text-lg text-darkPurple">New Schedule</span>
        </template>

        <div class="w-full flex flex-col justify-start gap-5">
          <div class="w-full flex justify-evenly gap-2 bg-guyabano p-2 rounded-md">
            <button
              v-for="schedule in scheduleTabs"
              :key="schedule.class"
              @click="payload.scheduleType = schedule.type"
              :class="[
                'py-2 px-4 rounded-md hover:bg-white text-sm hover:shadow capitalize',
                payload.scheduleType === schedule.type && 'bg-white shadow',
                schedule.class
              ]"
            >
              {{ schedule.type }}
            </button>
          </div>
          <div v-if="payload.scheduleType === 'work'" class="w-full flex flex-col justify-start gap-5">
            <div class="w-full flex flex-col gap-4 border-b border-dashed border-romanSilver pb-5">
              <c-text label="Name" placeholder="XXXXXX" :rules="['required']" v-model="payload.name" />
              <c-text label="Description" placeholder="XXXXXX" :rules="['required']" v-model="payload.description" />
              <validation-provider class="w-full" name="functions" rules="required" v-slot="{ errors }">
                <label for="functions" class="date-label">Location</label>
                <v-select
                  :clearable="false"
                  class="style-select"
                  label="name"
                  name="location"
                  :reduce="option => option.id"
                  :options="locationOptions"
                  :close-on-select="true"
                  v-model="payload.locationId"
                  placeholder="XXXXXX"
                  :rules="['required']"
                />
                <small class="text-desire">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="w-full flex flex-col justify-start gap-6">
              <div class="w-full flex flex-col justify-start gap-4">
                <div class="w-full flex justify-between items-center">
                  <p class="text-base font-semibold text-darkPurple">Function(s)</p>
                  <div @click="temp.func ? $delete(temp, 'func') : $set(temp, 'func', true)">
                    <span class="flex p-2 rounded hover:bg-cultured cursor-pointer">
                      <c-icon v-if="temp.func" icon-name="chevronDown" size="xs" />
                      <c-icon v-else icon-name="chevronRight" size="xs" />
                    </span>
                  </div>
                </div>
                <div v-if="temp.func" class="bg-guyabano rounded-md p-4">
                  <validation-provider class="w-full" name="functions" rules="required" v-slot="{ errors }">
                    <label for="functions" class="date-label">Function(s)</label>
                    <v-select
                      :clearable="false"
                      class="style-select"
                      multiple
                      label="name"
                      name="functions"
                      :reduce="option => option.id"
                      :options="functionOptions"
                      :close-on-select="false"
                      v-model="payload.functionIds"
                      placeholder="XXXXXX"
                      :rules="['required']"
                    />
                    <small class="text-desire">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>
              <div class="w-full flex flex-col justify-start gap-4">
                <div class="w-full flex justify-between items-center">
                  <p class="text-base font-semibold text-darkPurple">Job Level(s)</p>
                  <div @click="temp.level ? $delete(temp, 'level') : $set(temp, 'level', true)">
                    <span class="flex p-2 rounded hover:bg-cultured cursor-pointer">
                      <c-icon v-if="temp.level" icon-name="chevronDown" size="xs" />
                      <c-icon v-else icon-name="chevronRight" size="xs" />
                    </span>
                  </div>
                </div>
                <div v-if="temp.level" class="bg-guyabano rounded-md p-4">
                  <validation-provider class="w-full" name="jobLevel" rules="required" v-slot="{ errors }">
                    <label for="jobLevel" class="date-label">Job Level(s)</label>
                    <v-select
                      :clearable="false"
                      class="style-select"
                      multiple
                      label="name"
                      name="jobLevel"
                      :reduce="option => option.id"
                      :options="levelOptions"
                      :close-on-select="false"
                      v-model="payload.levelIds"
                      placeholder="XXXXXX"
                      :rules="['required']"
                    />
                    <small class="text-desire">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>
              <div class="w-full flex flex-col justify-start gap-4">
                <div class="w-full flex justify-between items-center">
                  <p class="text-base font-semibold text-darkPurple">Designation(s)</p>
                  <div @click="temp.desig ? $delete(temp, 'desig') : $set(temp, 'desig', true)">
                    <span class="flex p-2 rounded hover:bg-cultured cursor-pointer">
                      <c-icon v-if="temp.desig" icon-name="chevronDown" size="xs" />
                      <c-icon v-else icon-name="chevronRight" size="xs" />
                    </span>
                  </div>
                </div>
                <div v-if="temp.desig" class="bg-guyabano rounded-md p-4">
                  <validation-provider class="w-full" name="designations" rules="required" v-slot="{ errors }">
                    <label for="designations" class="date-label">Designation(s)</label>
                    <v-select
                      :clearable="false"
                      class="style-select"
                      multiple
                      label="name"
                      name="designations"
                      :reduce="option => option.id"
                      :options="designationOptions"
                      :close-on-select="false"
                      v-model="payload.designationIds"
                      placeholder="XXXXXX"
                      :rules="['required']"
                    />
                    <small class="text-desire">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>
              <div class="w-full flex flex-col justify-start gap-4">
                <div class="w-full flex justify-between items-center">
                  <p class="text-base font-semibold text-darkPurple">Exception(s)</p>
                  <div @click="temp.excep ? $delete(temp, 'excep') : $set(temp, 'excep', true)">
                    <span class="flex p-2 rounded hover:bg-cultured cursor-pointer">
                      <c-icon v-if="temp.excep" icon-name="chevronDown" size="xs" />
                      <c-icon v-else icon-name="chevronRight" size="xs" />
                    </span>
                  </div>
                </div>
                <div v-if="temp.excep" class="bg-guyabano rounded-md p-4">
                  <div class="flex flex-col justify-start gap-6">
                    <div class="flex justify-between items-center">
                      <p class="text-sm font-semibold text-blueCrayola">Work Mode:</p>
                      <c-radio
                        space-between="mr-2 ml-8"
                        v-model="payload.workMode"
                        radio-name="workMode"
                        :options="[{name: 'On-Site', value: 'site',}, {name: 'Hybrid', value: 'hybrid'}]"
                        :rules="['required']"
                      />
                    </div>
                    <div v-if="payload.workMode === 'hybrid'" class="flex justify-between items-center">
                      <div class="flex justify-between items-center gap-5">
                        <p class="text-base text-jet">On Site Days</p>
                        <c-text
                          type="number"
                          min="0"
                          placeholder="0"
                          class="w-20"
                          :rules="['required']"
                          v-model="payload.siteDays"
                        />
                      </div>
                      <div class="flex justify-beteeen items-center gap-5">
                        <p class="text-base text-jet">Remote Days</p>
                        <c-text
                          type="number"
                          min="0"
                          placeholder="0"
                          class="w-20"
                          :rules="['required']"
                          v-model="payload.remoteDays"
                        />
                      </div>
                    </div>
                    <c-radio
                      v-if="payload.workMode === 'hybrid'"
                      space-between="mr-3"
                      class="exception-radio-class"
                      radio-name="workFlexibility"
                      v-model="payload.flexibility"
                      :rules="['required']"
                      :options="[
                        { name: 'Define On Site Days', value: 'site' },
                        { name: 'Allow employees to select days for On site and Remote work', value: 'flexible' },
                      ]"
                    />
                    <div class="w-full flex justify-evenly gap-3">
                      <button v-for="(workDay, i) in workDaysOptions" :key="workDay.name"
                        @click="onSelectWorkDays(workDay.value, i)"
                        :class="{
                        'py-2 px-3 rounded text-xs uppercase': true,
                        'shadow bg-white text-flame font-black hover:text-desire': workDay.active,
                        'bg-cultured text-romanSilver font-semibold hover:text-flame': !workDay.active
                        }"
                      >
                        {{ workDay.name }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full flex flex-col justify-start gap-4">
                <div class="w-full flex justify-between items-center">
                  <p class="text-base font-semibold text-darkPurple">Period(s)</p>
                  <div @click="temp.period ? $delete(temp, 'period') : $set(temp, 'period', true)">
                    <span class="flex p-2 rounded hover:bg-cultured cursor-pointer">
                      <c-icon v-if="temp.period" icon-name="chevronDown" size="xs" />
                      <c-icon v-else icon-name="chevronRight" size="xs" />
                    </span>
                  </div>
                </div>
                <div v-if="temp.period" class="bg-guyabano rounded-md p-4">
                  <div class="flex flex-col justify-start gap-4">
                    <div class="w-full flex flex-col justify-start gap-1">
                      <label for="startDate" class="text-sm font-normal text-jet">Start Date</label>
                      <input
                        name="startDate"
                        type="date"
                        class="border border-romanSilver rounded-md p-2"
                        v-model="payload.startDate"
                        :rules="['required']"
                      />
                    </div>
                    <div class="flex justify-between items-center gap-4">
                      <div class="w-full flex flex-col justify-start gap-1">
                        <label for="startTime" class="text-sm font-normal text-jet">Start Time</label>
                        <input
                          name="startTime"
                          type="time"
                          class="border border-romanSilver rounded-md p-2"
                          v-model="payload.startTime"
                          :rules="['required']"
                        />
                      </div>
                      <div class="w-full flex flex-col justify-start gap-1">
                        <label for="endTime" class="text-sm font-normal text-jet">End Time</label>
                        <input
                          name="endTime"
                          type="time"
                          class="border border-romanSilver rounded-md p-2"
                          v-model="payload.endTime"
                          :rules="['required']"
                        />
                      </div>
                    </div>
                    <div class="flex flex-col justify-start gap-4">
                      <div class="flex justify-between items-center">
                        <p class="text-sm font-semibold text-blueCrayola">Ends:</p>
                        <c-radio
                          space-between="mr-2 ml-8"
                          v-model="payload.periodEnd"
                          :options="[{name: 'Never', value: 'never'}, {name: 'On', value: 'on'}]"
                          :rules="['required']"
                        />
                      </div>
                      <div v-if="payload.periodEnd === 'on'" class="w-full flex flex-col justify-start gap-1">
                        <label for="endDate" class="text-sm font-normal text-jet">End Date</label>
                        <input
                          name="endDate"
                          type="date"
                          class="border border-romanSilver rounded-md p-2"
                          v-model="payload.endDate"
                          :rules="['required']"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full flex flex-col justify-start gap-4">
                <div class="w-full flex justify-between items-center">
                  <p class="text-base font-semibold text-darkPurple">Break(Optional)</p>
                  <div @click="temp.break ? $delete(temp, 'break') : $set(temp, 'break', true)">
                    <span class="flex p-2 rounded hover:bg-cultured cursor-pointer">
                      <c-icon v-if="temp.break" icon-name="chevronDown" size="xs" />
                      <c-icon v-else icon-name="chevronRight" size="xs" />
                    </span>
                  </div>
                </div>
                <div v-if="temp.break" class="bg-guyabano rounded-md p-4">
                  <div class="flex flex-col justify-start gap-4">
                    <validation-observer ref="breakItemsObserver" rules="required" v-slot="{ handleSubmit }">
                      <div class="flex flex-col justify-start gap-4 border-b border-romanSilver border-dashed pb-4">
                        <validation-provider class="w-full" name="Break Type" rules="required" v-slot="{ errors }">
                          <label for="Break Type" class="date-label">Type</label>
                          <v-select
                            :clearable="false"
                            class="style-select"
                            label="name"
                            name="Break Type"
                            :reduce="option => option.value"
                            :options="breakOptions"
                            :close-on-select="true"
                            v-model="breakItem.type"
                            placeholder="XXXXXX"
                            :rules="['required']"
                          />
                          <small class="text-flame">{{ errors[0] }}</small>
                        </validation-provider>
                        <c-text
                          label="Description"
                          placeholder="XXXXXX"
                          v-model="breakItem.description"
                          :rules="['required']"
                        />
                        <div class="flex justify-between items-center gap-4">
                          <validation-provider class="w-full flex flex-col justify-start gap-1" name="Start Time" rules="required" v-slot="{ errors }">
                            <label for="Start Time" class="text-sm font-normal text-jet">Start Time</label>
                            <input
                              name="Start Time"
                              type="time"
                              class="border border-romanSilver rounded-md p-2"
                              v-model="breakItem.startTime"
                              :rules="['required']"
                            />
                            <small class="text-flame">{{ errors[0] }}</small>
                          </validation-provider>
                          <validation-provider class="w-full flex flex-col justify-start gap-1" name="End Time" rules="required" v-slot="{ errors }">
                            <label for="End Time" class="text-sm font-normal text-jet">End Time</label>
                            <input
                              name="End Time"
                              type="time"
                              class="border border-romanSilver rounded-md p-2"
                              v-model="breakItem.endTime"
                              :rules="['required']"
                            />
                            <small class="text-flame">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                        <div class="flex w-full" @click="handleSubmit(onSubmitBreakItems(breakItem))">
                          <div class="flex justify-start items-center gap-2 cursor-pointer hover:underline text-flame">
                            <c-icon icon-name="icon-add" size="s" class="text-flame" />
                            <button type="submit" class="text-base font-semibold text-flame">Add Another Break</button>
                          </div>
                        </div>
                      </div>
                    </validation-observer>
                    <div class="w-full flex flex-col justify-start items-start gap-4">
                      <div class="w-full" v-for="breakItemItem in payload.breakItems" :key="breakItemItem.type">
                        <div class="w-full flex justify-between items-center">
                          <div class="flex flex-col justify-start gap-0">
                            <div class="flex justify-start items-center gap-2">
                              <p class="text-base font-semibold text-jet capitalize">{{ breakItemItem.type }}</p>
                              <p class="text-sm text-romanSilver font-normal">
                                • {{ breakItemItem.startTime }} - {{ breakItemItem.endTime }}
                              </p>
                            </div>
                            <p class="text-xs text-romanSilver font-normal">{{ breakItemItem.description }}</p>
                          </div>
                          <div class="flex" @click="payload.breakItems.splice(breakItemItem, 1)">
                            <span class="p-2 rounded hover:bg-cultured cursor-pointer">
                              <c-icon icon-name="delete" size="xs" class="text-flame" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="flex flex-col justify-start gap-4">
            <c-text
              label="Event Name"
              placeholder="Enter"
              :rules="['required']"
              v-model="payload.name"
            />
            <div class="flex flex-col justify-start gap-2">
              <label for="eventDesc" class="text-sm text-darkPurple">Description</label>
              <c-textarea
                name="eventDesc"
                label="Description"
                placeholder="--Enter--"
                :rules="['required']"
                v-model="payload.description"
              />
            </div>
            <c-text
              label="Location/Venue"
              placeholder="Enter"
              v-model="payload.locationId"
            />
            <div class="bg-guyabano rounded-md p-4 flex flex-col justify-start gap-4">
              <div class="flex flex-col justify-start gap-4">
                <div class="flex justify-between items-center">
                  <p class="text-sm text-darkPurple font-normal">Date and Time</p>
                  <div class="flex justify-start items-center gap-2">
                    <switch-toggle class="mt-2" :rules="['required']" v-model="payload.isAllDay" />
                    <p class="text-sm text-darkPurple font-normal">All day</p>
                  </div>
                </div>
                <div class="w-full flex flex-col justify-start gap-1">
                  <label for="startDate" class="text-sm font-normal text-jet">Start Date</label>
                  <input
                    name="startDate"
                    type="date"
                    class="border border-romanSilver rounded-md p-2"
                    :rules="['required']"
                    v-model="payload.startDate"
                  />
                </div>
                <div class="flex justify-between items-center gap-4">
                  <div class="w-full flex flex-col justify-start gap-1">
                    <label for="startTime" class="text-sm font-normal text-jet">Start Time</label>
                    <input
                      name="startTime"
                      type="time"
                      class="border border-romanSilver rounded-md p-2"
                      :rules="['required']"
                      v-model="payload.startTime"
                    />
                  </div>
                  <span class="text-base text-romanSilver mt-2">to</span>
                  <div class="w-full flex flex-col justify-start gap-1">
                    <label for="endTime" class="text-sm font-normal text-jet">End Time</label>
                    <input
                      name="endTime"
                      type="time"
                      class="border border-romanSilver rounded-md p-2"
                      :rules="['required']"
                      v-model="payload.endTime"
                    />
                  </div>
                </div>
              </div>
              <div class="flex flex-col justify-start gap-4">
                <div class="flex justify-start items-center gap-4">
                  <p class="text-sm font-semibold text-blueCrayola">Repeat:</p>
                  <c-radio
                    space-between="mr-2 ml-3"
                    style="display:flex"
                    :rules="['required']"
                    radio-name="Repeat"
                    v-model="payload.repeat"
                    :options="[
                      {name: 'Daily', value: 'daily'},
                      {name: 'Weekly', value: 'weekly'},
                      {name: 'Monthly', value: 'monthly'},
                      {name: 'Custom', value: 'custom'},
                    ]"
                  />
                </div>
                <div v-if="payload.repeat === 'custom'" class="w-full flex justify-evenly gap-3">
                  <button v-for="(workDay, i) in workDaysOptions" :key="workDay.name"
                    @click="onSelectWorkDays(workDay.value, i)"
                    :class="{
                    'py-2 px-3 rounded text-xs uppercase': true,
                    'shadow bg-white text-flame font-black hover:text-desire': workDay.active,
                    'bg-cultured text-romanSilver font-semibold hover:text-flame': !workDay.active
                    }"
                  >
                    {{ workDay.name }}
                  </button>
                </div>
              </div>
              <div class="flex flex-col justify-start gap-2 border-b border-romanSilver border-dashed pb-4">
                <div class="flex justify-between items-center">
                  <p class="text-sm text-darkPurple font-normal">Reminder</p>
                  <div class="flex justify-start items-center gap-2">
                    <switch-toggle class="mt-2" :rules="['required']" v-model="payload.hasReminder" />
                    <p class="text-sm text-darkPurple font-normal">Do not remind me</p>
                  </div>
                </div>
                <div class="flex justify-start gap-4">
                  <c-text
                    type="number"
                    placeholder="0"
                    class="w-24"
                    :rules="['required']"
                    v-model="payload.reminder"
                  />
                  <c-select
                    placeholder="Select"
                    class="w-32 bg-white"
                    :rules="['required']"
                    :options="['Seconds', 'Minutes', 'Hours']"
                    v-mode="payload.interval"
                  />
                </div>
                <p class="text-base text-jet font-normal">before event starts.</p>
              </div>
              <div class="w-full flex flex-col justify-start gap-4">
                <div class="w-full flex justify-between items-center">
                  <p class="text-base font-semibold text-darkPurple">Guests (Optional)</p>
                  <div class="" @click="isOpenEmployees = true">
                    <div class="flex justify-start items-center gap-2 cursor-pointer hover:underline text-flame">
                      <c-icon icon-name="icon-add" size="s" class="text-flame" />
                      <p class="text-base font-semibold text-flame">Add Employees</p>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col justify-start gap-4">
                  <div v-for="employee in selectedEmployees" :key="employee.userId">
                    <div class="bg-white shadow-md rounded-md p-2 flex justify-between items-center gap-2">
                      <div class="flex justify-start items-start gap-2">
                        <div>
                          <span v-if="employee.photo" class="flex justify-center items-center w-12 h-12">
                            <img :src="employee.photo" class="w-12 h-12 rounded-full" alt="profile photo" />
                          </span>
                          <div v-else class="flex justify-center items-center w-12 h-12 rounded-full border">
                            <p class="text-blueCrayola text-center font-semibold text-lg p-2">
                              {{ $getInitials(`${employee.fname} ${employee.lname}`) }}
                            </p>
                          </div>
                        </div>
                        <div class="flex flex-col justify-start gap-0">
                          <p class="font-semibold text-jet text-base">
                            {{ employee.fname }} {{ employee.lname }}
                          </p>
                          <span class="font-normal text-romanSilver text-sm">
                            {{ employee.userDesignation && employee.userDesignation.name }},
                            {{ employee.orgFunction && employee.orgFunction.name }}
                          </span>
                        </div>
                      </div>
                      <div class="" @click="onDeleteEmployees(employee, 'initiate')">
                        <span class="flex p-2 rounded hover:bg-cultured cursor-pointer">
                          <c-icon icon-name="icon-cancel" size="xs" class="text-darkPurple" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RightSideBar>
    </validation-observer>

    <RightSideBar
      v-if="isOpenEmployees"
      @close="isOpenEmployees = false"
      :loading="isFetchingEmployees"
      @submit="onSearchEmployees({}, 'submit')"
      submit="Save"
      width="width:520px"
      button-class="bg-dynamicBackBtn text-white rounded"
    >
      <template v-slot:title>
        <span class="font-bold text-lg text-darkPurple">Add Employees</span>
      </template>

        <div class="flex flex-col justify-start gap-12">
          <div class="w-full flex flex-col justify-start items-start gap-6">
            <p class="text-darkPurple">Select employees to invite</p>
            <div class="w-full flex justify-between items-center relative">
              <search-input
                class="w-full"
                @input="$queryBuilder({ search: $event }, getOrganisationEmployees)"
                placeholder="Search employee(s) or filter by"
                v-model="temp.searchPhrase"
              />
              <span class="absolute right-4 cursor-pointer">
                <c-icon icon-name="sort_size" size="xs" />
              </span>
            </div>
          </div>
          <div>
            <div v-for="(employee, i) in employeesOptions" :key="JSON.stringify(employee) + i"
              class="w-full flex flex-col justify-start items-start gap- border-b border-romanSilver border-dashed py-4"
            >
              <div class="w-full flex justify-between items-center">
                <div class="flex justify-start items-start gap-2" :class="{'justify-end': i % 2}">
                  <div>
                    <span v-if="employee.photo" class="flex justify-center items-center w-11 h-11">
                      <img :src="employee.photo" class="w-11 h-11 rounded" alt="profile photo" />
                    </span>
                    <div v-else class="flex justify-center items-center w-11 h-11 rounded border">
                      <span class="text-blueCrayola text-center font-semibold text-lg p-2">
                        {{ $getInitials(`${employee.fname} ${employee.lname}`) }}
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-col justify-start gap-1">
                    <p class="font-semibold text-darkPurple text-base">
                      {{ employee.fname }} {{ employee.lname }}
                    </p>
                    <div class="flex justify-start items-center gap-2">
                      <p class="font-bold text-xs text-romanSilver uppercase">
                        {{ employee.orgFunction && employee.orgFunction.name }}
                      </p>
                      <p class="font-semibold text-xs text-romanSilver uppercase">
                        • {{ employee.userDesignation && employee.userDesignation.name }}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <c-checkbox
                    v-model="employee.isChecked"
                    @change="onSearchEmployees(employee, 'checked')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
    </RightSideBar>

    <c-modal v-if="isOpenDeleteEmployee">
      <c-card class="flex flex-col gap-5 max-w-max py-3 px-5">
        <p class="text-jet text-base font-bold border-b border-solid border-romanSilver">
          Action Required!
        </p>
        <div class="flex flex-col justify-start items-start gap-0">
          <p class="text-jet text-base font-semibold">
            Do you want to delete this employee?
          </p>
        </div>
        <div class="w-full flex justify-center gap-4">
          <c-button variant="primary btn-bg c-button" label="Delete" @onClick="onDeleteEmployees({}, 'delete')" />
          <c-button variant="secondary c-button" label="Cancel" @onClick="isOpenDeleteEmployee = false" />
        </div>
      </c-card>
    </c-modal>

    <FilterTable
      v-if="isOpenFilter"
      :filter-data="filterOptions"
      @close="isOpenFilter = false"
      @applyFilter="onSubmitFilter($event)"
    />
  </div>
</template>

<script>
import { format } from "date-fns"
import Calendar from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import VSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import CTable from "@scelloo/cloudenly-ui/src/components/table"
import CCard from "@scelloo/cloudenly-ui/src/components/card"
import CMenu from "@scelloo/cloudenly-ui/src/components/Menu"
import CSelect from "@scelloo/cloudenly-ui/src/components/select"
import CButton from "@scelloo/cloudenly-ui/src/components/button"
import CRadio from "@scelloo/cloudenly-ui/src/components/radio"
import CText from "@scelloo/cloudenly-ui/src/components/text"
import CCheckbox from "@scelloo/cloudenly-ui/src/components/checkbox"
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area"
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input"
import CIcon from "@/components/Icon"
import CModal from "@/components/Modal"
import CardFooter from "@/components/CardFooter"
import FilterTable from "@/components/FilterTable"
import RightSideBar from "@/components/RightSideBar"
import SwitchToggle from "@/components/Switch"

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VSelect,
    Calendar,
    BackButton,
    CButton,
    CCheckbox,
    CText,
    CModal,
    CTextarea,
    CRadio,
    SearchInput,
    CMenu,
    CCard,
    CIcon,
    CSelect,
    CardFooter,
    CTable,
    FilterTable,
    RightSideBar,
    SwitchToggle
  },
  computed: {
    radiusInMeters() {
      return Array.from({ length: 50 }, (_, i) => i + 1)
    },
    filterOptions() {
      return [
        { header: 'Location', value: 'location', optionsBox: this.locationOptions },
        { header: 'Function', value: 'function', optionsBox: [] },
        { header: 'Designation', value: 'designation', optionsBox: [] },
        { header: 'Job Level', value: 'jobLevel', optionsBox: [] },
        { header: 'Employment Type', value: 'employmentType', optionsBox: [] },
        { header: 'Status', value: 'status', optionsBox: this.statusOptions },
      ]
    },
  },
  data() {
    return {
      temp: {},

      isOpen: false,
      isOpenFilter: false,
      isFetching: false,
      isSubmitting: false,
      isOpenSchedule: false,
      isOpenEmployees: false,
      isFetchingEmployees: false,
      isOpenDeleteEmployee: false,
      analytics: {},
      absenceOptions: [],
      absenceMeta: {},
      activeView: 'register',
      selectedLocation: {},
      selectedDate: new Date(),
      activeConflict: {},
      calendarData: [],
      conflictedLeave: [],
      scheduleOptions: [],
      scheduleMeta: {},
      tempActive: [],
      functionOptions: [],
      locationOptions: [],
      levelOptions: [],
      designationOptions: [],
      employeesOptions: [],
      selectedEmployees: [],
      activeEmployee: {},
      breakItem: {
        type: null,
        description: null,
        startTime: null,
        endTime: null,
      },
      headers: [
        { title: "Schedule ID #", value: "tag" },
        { title: "Schedule Title", value: "name" },
        { title: "Start Date", value: "startDate" },
        { title: "End Date", value: "endDate" },
        { title: "Work Days", value: "workDays" },
        { title: "Employees", value: "employees" },
        { title: "Status", value: "status" },
        { title: "", value: "id", image: true },
      ],
      statusOptions: [
        { name: 'Active', id: 'active' },
        { name: 'Inactive', id: 'inactive' }
      ],
      filterByWeekOptions: [
        { name: 'All', id: 'all' },
        { name: 'Today', id: 'today' },
        { name: 'This Week', id: 'this_week' },
        { name: 'This Month', id: 'this_month' },
        { name: 'Custom', id: 'custom' },
      ],

      absentEmployees: [
        { fname: 'Akande', lname: 'Ladipo', orgFunction: 'HR Associate', userDesignation: 'ADMIN' },
        { fname: 'Akande', lname: 'Ladipo', orgFunction: 'HR Associate', userDesignation: 'ADMIN' },
        { fname: 'Akande', lname: 'Ladipo', orgFunction: 'HR Associate', userDesignation: 'ADMIN' },
      ],
      scheduleTabs: [
        { type: 'work', class: 'text-mediumSeaGreen' },
        { type: 'meeting', class: 'text-blueCrayola' },
        { type: 'event', class: 'text-carrotOrange' },
        { type: 'to-do', class: 'text-darkPurple' },
        { type: 'reminder', class: 'text-desire' },
      ],
      workDaysOptions: [
        { name: 'Mon', value: 'M', active: false },
        { name: 'Tue', value: 'T', active: false },
        { name: 'Wed', value: 'W', active: false },
        { name: 'Thu', value: 'TH', active: false },
        { name: 'FRI', value: 'F', active: false },
        { name: 'SAT', value: 'S', active: false },
        { name: 'SUN', value: 'SU', active: false },
      ],
      breakOptions: [
        { name: 'Lunch Break', value: 'lunch' },
        { name: 'Coffee Break', value: 'coffee' },
      ],

      payload: {
        scheduleType: 'work',
        name: '',
        description: '',
        locationId: '',
        designationIds: [],
        functionIds: [],
        levelIds: [],
        employeeIds: [],
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        workMode: 'site',
        workDays: [],
        siteDays: null,
        remoteDays: null,
        type: 'org',
        flexibility: 'site',
        periodEnd: 'never',
        breakDescription: null,
        breakItems: [],
        hasReminder: false,
        reminder: null,
        interval: '',
        isAllDay: false,
      },
    };
  },
  methods: {
    onSubmitSchedule() {
      this.isSubmitting = true

      const payload = {
        ...this.payload,
        startDate: format(new Date(this.payload.startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(this.payload.endDate), 'yyyy-MM-dd')
      }

      this.$_createScheduleEmployee(payload).then(({ data }) => {
        this.$toasted.success(data.message, { duration: 5000 })
        this.isSubmitting = false
        this.isOpenSchedule = false
        this.getSchedules()
      }).catch((error) => {
        this.$toasted.error(error, { duration: 5000 })
        this.isSubmitting = false
      })
    },
    onSubmitFilter(options){
      this.$queryBuilder(options, this.getOrgClockInOut)
      this.isOpenFilter = false
    },
    async onSwitchActiveView(activeView) {
      this.activeView = activeView
      const queryParams = {
        month: this.selectedDate.getMonth() + 1,
        year: this.selectedDate.getFullYear(),
        view: this.activeView
      }
      await this.$queryBuilder(queryParams, this.getSchedules)
    },
    async onChangeView($event){
      this.selectedDate = $event.startDate
      const queryParams = {
        month: this.selectedDate.getMonth() + 1,
        year: this.selectedDate.getFullYear(),
        view: this.activeView
      }
      await this.$queryBuilder(queryParams, this.getSchedules)
    },
    onSelectWorkDays(selectedDay, index) {
      if (this.payload.workDays.includes(selectedDay)) {
        this.payload.workDays.splice(selectedDay, 1)
        this.workDaysOptions[index].active = false
      } else {
        this.payload.workDays.push(selectedDay);
        this.workDaysOptions[index].active = true
      }
    },
    onSubmitBreakItems(breakItemPayload) {
      this.payload.breakItems.push(breakItemPayload)
      this.breakItem = { type: null, description: null, startTime: null, endTime: null }
    },
    onSearchEmployees(searchEmployee, actionType) {
      if (actionType === 'checked') {
        const foundIndex = this.selectedEmployees.findIndex(v => v.userId === searchEmployee.userId)
        if (searchEmployee.isChecked && foundIndex === -1) this.selectedEmployees.push(searchEmployee)
        else {
          this.selectedEmployees = this.selectedEmployees.filter((employee1) => (
            employee1.userId !== searchEmployee.userId
          ))
        }
      } else if (actionType === 'submit') {
        this.payload.employeeIds = this.selectedEmployees.map(item => item.userId)
        this.isOpenEmployees = false
      }
    },
    onDeleteEmployees(activeEmployee, actionType) {
      if (actionType === 'initiate') {
        this.activeEmployee = activeEmployee
        this.isOpenDeleteEmployee = true
      } else if (actionType === 'delete') {
        this.isOpenDeleteEmployee = false
        this.selectedEmployees = this.selectedEmployees.filter((employee) => (
            employee.userId !== this.activeEmployee.userId
          ))
      }

    },
    async getSchedules(params) {
      this.isFetching = true
      await this.$_getEmployeeSchedule(params).then(({ data }) => {
        this.scheduleOptions = data.allSchedules
        this.scheduleMeta = data.meta
        this.isFetching = false
      })
    },
    async getOrganisationEmployees(params) {
      this.isFetchingEmployees = true
      this.temp.searchPhrase = params.search
      await this.$_getOrganisationEmployees(params).then(({ data }) => {
        this.employeesOptions = data.data.employees
        this.isFetchingEmployees = false
      })
    },

    async bootstrapModule(){
      await this.getSchedules()

      this.$_getFunction().then(({ data }) => { this.functionOptions = data.functions })
      this.$_getHumanarLocations().then(({ data }) => { this.locationOptions = data.outlets })
      this.$_getLevels().then(({ data }) => { this.levelOptions = data.levels })
      this.$_getDesignations('').then(({ data }) => { this.designationOptions = data.designations })
      this.$queryBuilder({ perPage: 10 }, this.getOrganisationEmployees)
    }
  },
  async created() {
    this.bootstrapModule()
  }
};
</script>

<style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
  border: 0 !important;
}
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 50px !important;
}
.c-button {
  width: 100% !important;
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-class {
    background: #EEEEEE52;
    border-radius: 8px;
    padding: 24px;
    padding-top: 8px;
    border: 0 !important;
}
::v-deep .vuecal {
    border: 0 !important;
}
::v-deep .vuecal .vuecal__header {
    border: 0 !important;
}
::v-deep .vuecal .weekday-label {
    display: flex;
    justify-content: flex-start;
}
::v-deep .vuecal .weekday-label span {
    font-family: 'Nunito Sans';
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #878E99
}
::v-deep .vuecal .vuecal__cell {
    height: 126px !important;
    border-color: #878E99 !important;
    border-style: solid !important;
    border-width: 0.5px !important;
}
::v-deep .vuecal .vuecal__cell-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px;
}
::v-deep .exception-radio-class {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
</style>